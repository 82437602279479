@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

body {
	margin: 0;
	padding: 0;
	font-family: poppins;
	text-align: center;
}

.App-logo {
	height: 80px;
}

.App-header {
	background-color: #222;
	padding: 20px;
	color: white;
	height: 100vh;
}

.App-header .title {
	margin-top: 40px;
}

.App-header a {
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	transition: .2s all;
}

.App-header a:hover {
	opacity: .7;
}

.githubIcon {
	height: 30px;
	margin-right: 5px;
}

.Countdown {
	margin: 10px auto;
	padding-bottom: 20px;
}

.Countdown-col {
	display: inline-block;
}

.Countdown-col-element {
	display: inline-block;
	margin: 0 20px;
	display: flex;
	flex-direction: column;
}

.Countdown-col-element strong {
	font-size: 50px;
}